@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Symbols+Outlined:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body { margin: 0; font-family: Inter, sans-serif; }

.success-toaster {
    --mdc-snackbar-container-color: #F6FEF9 !important;
    --mdc-snackbar-supporting-text-color: #067647 !important;
    --mat-mdc-snack-bar-button-color: white !important;
    white-space: pre-wrap
}

.error-toaster {
    --mdc-snackbar-container-color: #FFFBFA !important;
    --mdc-snackbar-supporting-text-color: #B42318 !important;
    --mat-mdc-snack-bar-button-color: white !important;
    white-space: pre-wrap
}

.ql-editor {
    font-family: Inter, sans-serif;
    color: #101828;
    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

    }

    h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 0.5rem;
    }

    h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 0.5rem;
    }

    ol {
        list-style-type: none;
        list-style-type: decimal;
        padding-left: 1rem;
        list-style-position: outside;
    }

    ul {
        list-style-type: none;
        list-style-type: disc;
        padding-left: 1rem;
        list-style-position: outside;
    }
}